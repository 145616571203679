import React from 'react'
import Title from "../components/title"
import { Link } from 'gatsby'

export default function rfp({ lang }) {
    return (
        <section className="py-5 bg-light">
            {lang === "fi" ? (<Title title="Tarjouspyyntö"/>) : (<Title title="Offertförfrågan"/>)}
            <div className="col-10 col-sm-8 col-md-6 col mx-auto">
                <form action="https://formspree.io/xgenwaad" method="POST" className="was-validated">
                    <div className="form-group">
                        <label htmlFor="name">{lang === "fi" ? ('Nimi') : ('Namn')}</label>
                        <input type="text" className="form-control" name="name" id="name" placeholder="" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">{lang === "fi" ? ('Puhelinnumero') : ('Telefonnummer')}</label>
                        <input type="text" className="form-control" name="phone" id="phone" placeholder="" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">{lang === "fi" ? ('Sähköposti') : ('E-post')}</label>
                        <input type="text" className="form-control" name="email" id="email" placeholder="" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">{lang === "fi" ? ('Osoite') : ('Adress')}</label>
                        <input type="text" className="form-control" name="address" id="address" placeholder="" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">{lang === "fi" ? ('Viesti') : ('Meddelande')}</label>
                        <textarea className="form-control" rows="5" name="description" id="description" placeholder="" required />
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                            <label class="form-check-label" for="invalidCheck2">{lang === "fi" ? ('Hyväksyn') : ('Jag samtycker med')} {lang === "fi" ? ( <Link to="/fi/dsp">tietoturvakäytännön</Link> ) : ( <Link to="/dsp">dataskyddspolicyn</Link> )}</label>
                        </div>
                    </div>
                    <input type="hidden" name="_next" value="https://shmaleri.fi/thanks"/>
                    <button type="submit" className="btn btn-primary btn-block">{lang === "fi" ? ('Lähetä') : ('Skicka')}</button>
                </form>
            </div>
        </section>
    )
}
