import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import Proposal from "../components/rfp"
import PropTypes from 'prop-types';

const ProposalPage = ({ data, location }) => (
  <Layout location={location} lang="sv">
    <SEO title="Offertförfrågan" keywords={['Målare', 'Målarbeten', 'Fasadrenovering', 'Fasadtvätt', 'Vasa', 'Korsholm', 'Vasa med omnejd']} />
    <TopSection img={data.img.childImageSharp.fluid} title="SH-Måleri AB" styleClass="proposal-background" lang="sv" />
    <Proposal lang="sv" />
  </Layout>
)

ProposalPage.propTypes = {
  location: PropTypes.object,
};

export const query = graphql`
  {
    img: file(relativePath: {eq: "background.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`

export default ProposalPage